<template>
  <div class="contents register">
    <div class="title flexB">
      <h2>메타데이터 일괄 수정</h2>
      <p>{{ name }} ({{ team }})</p>
    </div>
    <div class="box one">
      <h3>
        {{ targets.length }}개의 메타데이터를 수정합니다.
      </h3>
      <div :style="{ margin: '10px 0 20px' }">
        <p>아래는 값이 동일하지 않은 필드의 목록입니다. 아래 폼에서 빈칸으로 표시됩니다.</p>
        <el-table :data="targets" resizable border>
          <el-table-column
            prop="_id"
            label="ID"
            width="220"
          >
            <template slot="header">ID</template>
            <template slot-scope="scope">
              <router-link :to="`/admin/datamanage/register?id=${scope.row._id}`" :style="{
                color: '#409EFF',
                textDecoration: 'underline',
              }">
                {{ scope.row._id }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(mvf) in Object.entries(multivaluedFields).filter(([key]) => fieldName[key])"
            :key="mvf[0]"
            label="값"
            :prop="mvf[0]"
            show-overflow-tooltip
            sortable
            :min-width="150"
          >
            <template slot="header" slot-scope="scope">
              {{ fieldName[scope.column.property] }}
            </template>
          </el-table-column>
        </el-table>
        <!-- {{ this.multivaluedFields.map() }} -->
      </div>
      <div class="registerBox">
        <div class="left">
          <div class="flexL">
            <p>폴더명</p>
            <input type="text" v-model="metadata.folderName" />
            <!-- <span v-if="editMode">{{ folderName }}</span> -->
          </div>
          <div class="flexL">
            <p>언어</p>
            <select v-model="metadata.language">
              <option value disabled>선택</option>
              <option value="ko">ko</option>
              <option value="en">en</option>
            </select>
          </div>
          <div class="flexL">
            <p>제작유형</p>
            <select
              v-model="metadata.productionType"
              @change="handleFirstDropdownChanged"
            >
              <option value disabled>선택</option>
              <option
                v-for="option in firstOptions"
                :value="option"
                :key="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
          <div class="flexL">
            <p>IP 유형</p>
            <select v-model="metadata.ipType">
              <option value disabled>선택</option>
              <option value="po">po</option>
              <option value="ta">ta</option>
              <option value="ti">ti</option>
              <option value="pota">pota</option>
              <option value="poti">poti</option>
              <option value="zl">zl</option>
            </select>
          </div>
          <div class="flexL">
            <p>콘텐츠유형</p>
            <select v-model="metadata.contentType">
              <option value disabled>선택</option>
              <option
                v-for="option in secondOptions"
                :value="option"
                :key="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
          <div class="flexL">
            <p>대표이미지</p>
            <div class="thumbnail-field">
              <el-upload
                class="upload-thumbnail"
                :on-remove="() => metadata.mainImage = ''"
                :on-change="handleThumbnailFileChange"
                drag
                action="#"
                :show-file-list="false"
                :auto-upload="false"
                :before-upload="beforeThumbnailUpload"
              >
                <img v-if="metadata.mainImage" :src="metadata.mainImage" class="thumbnail">
                <div class="info-msg" v-bind:style="{ 'display': metadata.mainImage ? null : 'flex' }">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">파일을 끌어다 놓거나 클릭해서 업로드</div>
                </div>
              </el-upload>
              <el-link type="primary" v-if="metadata.mainImage" v-bind:href="metadata.mainImage" target="_blank">
                {{ decodeURI(metadata.mainImage.split('/').slice(-2).join('/'))}}
              </el-link>
              <el-button v-show="metadata.mainImage" type="text" icon="el-icon-delete" @click="metadata.mainImage = ''"></el-button>
            </div>
          </div>
          <div class="flexL">
            <p>서버 디렉토리 경로</p>
            <input type="text" v-model="metadata.serverPath" />
          </div>
          <div class="flexL">
            <p>영상 구분</p>
            <select v-model="metadata.videoType">
              <option value disabled>선택</option>
              <option value="2D">2D</option>
              <option value="3D">3D</option>
              <option value="Real">Real</option>
            </select>
          </div>
          <div class="flexL">
            <p>오디오 언어</p>
            <el-select v-model="metadata.audioLanguages" multiple placeholder="선택" options>
              <el-option
                v-for="o in audioLanguageOptions"
                :key="o"
                :label="o"
                :value="o"
              ></el-option>
            </el-select>
          </div>
          <div class="flexL">
            <p>상영시간</p>
            <input type="text" v-model="metadata.duration" placeholder="예) 01:00:00" />
          </div>
          <div class="flexL">
            <p>시리즈제목</p>
            <input type="text" v-model="metadata.seriesTitleKorean" />
          </div>
          <div class="flexL">
            <p>Series Title</p>
            <input type="text" v-model="metadata.seriesTitleEnglish" />
          </div>
          <div class="flexL">
            <p>시리즈 시놉시스</p>
            <textarea
              v-model="metadata.seriesSynopsisKorean"
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div class="flexL">
            <p>Series Synopsis</p>
            <textarea
              v-model="metadata.seriesSynopsisEnglish"
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div class="flexL">
            <p>에피소드 회차</p>
            <input type="text" v-model="metadata.episodeNumber" />
          </div>
          <div class="flexL">
            <p>에피소드명(국문)</p>
            <input type="text" v-model="metadata.episodeTitleKorean" />
          </div>
          <div class="flexL">
            <p>에피소드명(영문)</p>
            <input type="text" v-model="metadata.episodeTitleEnglish" />
          </div>
        </div>
        <div class="right">
          <div class="flexL textBox">
            <p>장문 줄거리 <br />{{ byteCount }}/{{ byteLimit }} bytes</p>
            <textarea
              @input="checkByteLimit('long')"
              v-model="metadata.longSynopsis"
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div class="flexL textBox">
            <p>단문 줄거리 <br />{{ byteCount2 }}/{{ byteLimit2 }} bytes</p>
            <textarea
              @input="checkByteLimit('short')"
              v-model="metadata.shortSynopsis"
              class="small"
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div class="flexL">
            <p>작사가</p>
            <input type="text" v-model="metadata.lyricist" />
          </div>
          <div class="flexL">
            <p>작곡자</p>
            <input type="text" v-model="metadata.composer" />
          </div>
          <div class="flexL">
            <p>편곡자</p>
            <input type="text" v-model="metadata.arranger" />
          </div>
          <div class="flexL">
            <p>가수</p>
            <input type="text" v-model="metadata.singer" />
          </div>
          <div class="flexL">
            <p>곡 출처</p>
            <input type="text" v-model="metadata.songSource" />
          </div>
          <div class="flexL">
            <p>주연</p>
            <input
              type="text"
              v-model="metadata.mainCast"
              placeholder=",로 구분해서 적어주세요. 예) 뽀로로,크롱,루피"
            />
          </div>
          <div class="flexL">
            <p>등장인물</p>
            <input
              type="text"
              v-model="metadata.characters"
              placeholder=",로 구분해서 적어주세요. 예) 뽀로로,크롱,루피"
            />
          </div>
          <div class="flexL">
            <p>최초출시연도</p>
            <input
              type="text"
              v-model="metadata.initialReleaseYear"
              placeholder="예) 2023"
            />
          </div>
          <div class="flexL">
            <p>출시날짜</p>
            <input type="text" v-model="metadata.releaseDate" placeholder="예) 01.01" />
          </div>
          <div class="flexL">
            <p>장르</p>
            <input type="text" v-model="metadata.genre" />
          </div>
          <div class="flexL">
            <p>감독</p>
            <input type="text" v-model="metadata.director" />
          </div>
          <div class="flexL">
            <p>작가</p>
            <input type="text" v-model="metadata.writer" />
          </div>
          <div class="flexL">
            <p>성우</p>
            <input type="text" v-model="metadata.voiceActor" />
          </div>
          <div class="flexL">
            <p>제작사</p>
            <input type="text" v-model="metadata.productionCompany" />
          </div>
          <div class="flexL">
            <p>화질(only HD)</p>
            <input type="text" v-model="metadata.videoQuality" />
          </div>
          <div class="flexL">
            <p>화면비율</p>
            <input type="text" v-model="metadata.aspectRatio" />
          </div>
        </div>

        <!-- <div class="flexL">
          <p>요청상태</p>
          <p
            :class="{
              reject: status == 'REJECT',
              confirm: status == 'CONFIRM',
            }"
          >
            {{
              status == "REJECT"
                ? "반려"
                : status == "REQUEST"
                ? "승인대기"
                : status == "CONFIRM"
                ? "등록대기"
                : ""
            }}

            <span class="confirm">{{
              status == "CONFIRM"
                ? " (아래 정보 입력을 완료해야 최종적으로 등록처리 됩니다.)"
                : ""
            }}</span>
          </p>
        </div> -->
      </div>
      <el-dialog title="일괄 수정 확인" :visible.sync="updateConfirmDialogVisible">
        <el-descriptions
          :title="`총 ${targets.length}건 메타데이터에 대해서 다음 필드를 수정합니다.`" :column="1" border :labelStyle="{
          width: '200px',
        }">
          <el-descriptions-item v-for="([field, v]) in Object.entries(modifiedFields)" :key="field">
            <template slot="label">
              {{ fieldName[field] }}
            </template>
            {{ v }}
          </el-descriptions-item>
        </el-descriptions>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateConfirmDialogVisible = false">취소</el-button>
          <el-button type="primary" @click="submit">확인</el-button>
        </span>
      </el-dialog>
      <div class="buttonWrap mt50">
        <button class="basic" @click="handleUpdate">
          수정
        </button>
        <!-- <button v-if="isAdmin" class="deleteBtn" @click="deleteData">
          삭제
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  fetchMetadataDetails,
  updateMultipleMetadata,
  deleteMetadata,
  uploadImage,
} from "@/api/index";
import { mapState } from "vuex";
import { fieldName } from "@/assets/const";
export default {
  name: "dataUpdateMulti",

  data() {
    return {
      editMode: true,
      moment: moment,
      fieldName,
      dropdownItems: {
        series: ["series"],
        newmedia: ["story", "song_dance", "curation", "shorts", "sound", "etc"],
      },
      secondOptions: [],
      byteCount: 0,
      byteCount2: 0,
      byteLimit: 1800,
      byteLimit2: 160,
      isVisible: false,
      registerVisible: false,
      updateConfirmDialogVisible: false,
      confirmStatus: false,
      metadataIds: [],
      targets: [],
      origin: {},
      metadata: {},
      multivaluedFields: {},
      workDetails: [],
      modifiedFields: {},
      audioLanguageOptions: [
        '한국어', '광동어(홍콩)', '독일어', '러시아어', '루마니아어', '만다린(중국,대만)', '말레이어', '몽골어', '미얀마어', '베트남어', '벵골어(인도)', '스페인어(라틴)', '스페인어(스페인)', '아랍어', '영어', '우즈베크어', '웨일스어', '이탈리아어', '인도네시아어', '일본어','카자흐스탄어', '크메르어(캄보디아)', '키르기스어', '타갈로그(필리핀)', '타밀어(인도)', '태국어', '터키어', '페르시아어(이란)', '포르투갈어(브라질)', '포르투갈어(포르투갈)', '프랑스어', '헝가리어', '히브리어(이스라엘)', '힌디어(인도)',
      ],
    };
  },
  computed: {
    ...mapState(["name", "team", "isAdmin"]),
    firstOptions() {
      return Object.keys(this.dropdownItems);
    },
  },
  activated() {
    this.$store.dispatch("SET_NAVBAR", "0");
    if (this.$route.query.ids) {
      this.metadataIds = this.$route.query.ids;
      this.getMetadata();
    } else {
      this.$router.push("/admin/datamanage");
    }
  },
  methods: {
    handleThumbnailFileChange(file, fileList) {
      if (this.beforeThumbnailUpload(file)) {
        uploadImage(file.raw).then(({ data }) => {
          fileList.shift();
          this.metadata.mainImage = data.filename;
        });
      }
    },
    beforeThumbnailUpload(file) {
      const isImage = file.raw.type.startsWith('image/');
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isImage) {
        alert('이미지 파일이 아닙니다.');
      }
      if (!isLt5M) {
        alert('5MB 미만 파일만 업로드할 수 있습니다.');
      }
      return isImage && isLt5M;
    },
    checkByteLimit(type) {
      if (type == "long") {
        const byteCount = this.countBytes(this.metadata.longSynopsis);
        if (byteCount > this.byteLimit) {
          this.metadata.longSynopsis = this.truncateText(
            this.metadata.longSynopsis,
            this.byteLimit,
            "long"
          );
          this.byteCount = this.byteLimit;
          alert("최대 1800바이트를 초과하였습니다.");
        } else {
          this.byteCount = byteCount;
        }
      } else if (type == "short") {
        const byteCount2 = this.countBytes(this.metadata.shortSynopsis);
        if (byteCount2 > this.byteLimit2) {
          this.metadata.shortSynopsis = this.truncateText(
            this.metadata.shortSynopsis,
            this.byteLimit2,
            "short"
          );
          this.byteCount2 = this.byteLimit2;
          alert("최대 160바이트를 초과하였습니다.");
        } else {
          this.byteCount2 = byteCount2;
        }
      }
    },
    countBytes(text) {
      let count = 0;
      for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i);
        if (charCode <= 0x007f) {
          count += 1;
        } else {
          count += 2;
        }
      }
      return count;
    },
    truncateText(text, limit, type) {
      let truncatedText = "";
      if (type == "long") {
        let byteCount = 0;
        for (let i = 0; i < text.length; i++) {
          const charCode = text.charCodeAt(i);
          let charSize = 0;

          if (charCode <= 0x007f) {
            charSize = 1;
          } else {
            charSize = 2;
          }

          if (byteCount + charSize > limit) {
            break;
          }

          truncatedText += text[i];
          byteCount += charSize;
        }
        return truncatedText;
      } else if (type == "short") {
        let byteCount2 = 0;
        for (let i = 0; i < text.length; i++) {
          const charCode = text.charCodeAt(i);
          let charSize = 0;

          if (charCode <= 0x007f) {
            charSize = 1;
          } else {
            charSize = 2;
          }

          if (byteCount2 + charSize > limit) {
            break;
          }

          truncatedText += text[i];
          byteCount2 += charSize;
        }
        return truncatedText;
      }
    },
    isEqual(a, b) {
      // deep compare
      return JSON.stringify(a) === JSON.stringify(b);
    },
    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    getMetadata() {
      fetchMetadataDetails(this.metadataIds).then((res) => {
        if (res.data.status == 200) {
          this.targets = res.data.data;

          Object.keys(this.targets[0]).forEach((field) => {
            const values = this.targets.map((item) => item[field]);
            if (values.every((value) => this.isEqual(value, values[0]))) {
              this.metadata[field] = this.deepCopy(values[0]);
            } else {
              if (field == "audioLanguages") {
                this.metadata[field] = [];
              } else {
                this.metadata[field] = "";
              }
              this.multivaluedFields[field] = this.deepCopy(values);
            }
          });

          this.origin = this.deepCopy(this.metadata);

          this.byteCount = this.countBytes(this.metadata.longSynopsis);
          this.byteCount2 = this.countBytes(this.metadata.shortSynopsis);

          if (this.metadata.productionType == "series") {
            this.secondOptions = ["series"];
          } else if (this.metadata.productionType == "newmedia") {
            this.secondOptions = [
              "story",
              "song_dance",
              "curation",
              "shorts",
              "sound",
              "etc",
            ];
          }
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },

    handleFirstDropdownChanged() {
      this.metadata.contentType = "";
      this.secondOptions = this.dropdownItems[this.metadata.productionType] || [];
    },
    checkForUpdate() {
      let dummyWorkDetails = [];
      this.modifiedFields = {};
      for (let field in this.metadata) {
        if (!this.isEqual(this.metadata[field], this.origin[field])) {
          dummyWorkDetails.push(fieldName[field]);
          this.modifiedFields[field] = this.metadata[field];
        }
      }
      this.workDetails = dummyWorkDetails;
    },
    handleUpdate() {
      this.checkForUpdate();
      if (Object.keys(this.modifiedFields).length) {
        this.updateConfirmDialogVisible = true;
      } else {
        alert("수정한 필드가 없습니다.");
      }
    },
    submit() {
      this.checkForUpdate();

      if (Object.keys(this.modifiedFields).length === 0) {
        return;
      }

      let data = {
        metadataIds: this.metadataIds,
        workDetails: this.workDetails.join(","),
        ...this.modifiedFields,
      };

      updateMultipleMetadata(data).then((res) => {
        if (res.data.status == 200) {
          alert("메타데이터 정보가 반영 되었습니다.");
          this.updateConfirmDialogVisible = false;
          this.$router.push("/admin/datamanage");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    deleteData() {
      let result = confirm("메타데이터를 삭제하시겠습니까?");
      if (!result) {
        return;
      }
      deleteMetadata(this.metadataIds).then((res) => {
        if (res.data.status == 200) {
          alert("메타데이터 삭제 되었습니다.");
          this.$router.push("/admin/datamanage");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>

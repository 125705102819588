export const fieldName = {
  folderName: "폴더명",
  language: "언어",
  productionType: "제작유형",
  ipType: "IP 유형",
  contentType: "콘텐츠유형",
  mainImage: "대표이미지",
  serverPath: "서버 디렉토리 경로",
  videoType: "영상구분",
  audioLanguage: "오디어언어", // FIXME: remove
  audioLanguages: "오디오 언어",
  duration: "상영시간",
  seriesTitleKorean: "시리즈제목",
  seriesTitleEnglish: "Series Title",
  seriesSynopsisKorean: "시리즈 시놉시스",
  seriesSynopsisEnglish: "Series Synopsis",
  episodeNumber: "에피소드 회차",
  episodeTitleKorean: "에피소드명(국문)",
  episodeTitleEnglish: "에피소드명(영문)",
  longSynopsis: "장문 줄거리",
  shortSynopsis: "단문 줄거리",
  lyricist: "작사가",
  composer: "작곡자",
  arranger: "편곡자",
  singer: "가수",
  songSource: "곡 출처",
  mainCast: "주연",
  characters: "등장인물",
  initialReleaseYear: "최초출시연도",
  releaseDate: "출시날짜",
  genre: "장르",
  director: "감독",
  writer: "작가",
  voiceActor: "성우",
  productionCompany: "제작사",
  videoQuality: "화질(only HD)",
  aspectRatio: "화면비율",
};
